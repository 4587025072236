import type {
  Collection,
  Customer,
  MailingAddress,
  MailingAddressConnection,
  MediaConnection,
  Order,
  OrderLineItemConnection,
  Product,
  ProductVariantConnection,
} from '@shopify/hydrogen/storefront-api-types';
import groq from 'groq';

import type {SanityImage} from 'domains/sanity';

export const LINK_INTERNAL = groq`
  _key,
  _type,
  title,
  ...reference-> {
    "documentType": _type,
    (_type == "collection") => {
      "slug": "/collections/" + store.slug.current,
    },
    (_type == "home") => {
      "slug": "/",
    },
    (_type == "page") => {
      "slug": "/pages/" + slug.current,
    },
    (_type == "product") => {
      "slug": "/products/" + store.slug.current,
    },
    (_type == "category") => {
      "slug": "/blogs/" + slug.current,
    },
  }
`;

export interface SanitySlug {
  _type: string;
  current: string;
}

export interface SanityColour {
  title: string;
  value: string;
}

export interface SanityAssetImage {
  _type: 'image';
  altText?: string;
  blurDataURL: string;
  height: number;
  url: string;
  width: number;
}

export interface ShopifyCollection {
  imageUrl?: string;
  slug?: SanitySlug;
}
export interface SanityShopifyProduct {
  _type: 'product';
  _createdAt: string;
  _updatedAt: string;
  _id: string;
  _rev: string;
  gid: string;
  store: ShopifyProduct[];
}

export type SanityLink = SanityLinkExternal | SanityLinkInternal;

export type SanityLinkExternal = {
  _key: string;
  _type: 'linkExternal';
  newWindow?: boolean;
  url: string;
  title: string;
};

export type SanityLinkInternal = {
  _key: string;
  _type: 'linkInternal';
  documentType: string;
  slug?: string;
  title: string;
  reference: {
    documentType: 'collection' | 'home' | 'page' | 'product' | 'category';
    slug?: string;
  };
};
//reference

export type SanitySeo = {
  description?: string;
  image?: SanityAssetImage;
  title: string;
};

export interface SanitySlug {
  _type: string;
  current: string;
}
export interface ShopifyCollection {
  imageUrl?: string;
  slug?: SanitySlug;
}

export interface SanityObjectBase {
  _type: string;
  _createdAt: string;
  _updatedAt: string;
  _id: string;
  _rev: string;
}

interface ShopifyProduct extends Omit<Product, 'variants'> {
  slug?: SanitySlug;
  previewImageUrl: string;
  gid: string;
  variants: {
    _type: 'productVariant';
    _createdAt: string;
    _updatedAt: string;
    _id: string;
    _rev: string;
    store: {
      previewImageUrl: string;
      option1: string;
      option2: string;
      gid: string;
    } & Product;
  };
}

export function truncate(str: string, num = 155): string {
  if (typeof str !== 'string') return '';
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num - 3) + '...';
}

export interface SidebarItem {
  _key: string;
  _type: 'custom.sidebarItem';
  icon: SanityImage;
  title: string;
  type: string;
  url: string;
}

export type routeLocale = 'en' | 'zh_tw';
